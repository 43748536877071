import { Link, Outlet } from "react-router-dom";
import {
  //BrowserView,
  //MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import useMainStore from "../../zustand/mainStore";
import MobileMainMenu from "./MobileMainMenu";
import { IoMenu } from "react-icons/io5";
import Logo from "../../images/crow-logo-sm.png";

const linkClassNames = `flex items-center justify-center w-full md:w-fit mr-1 px-4 h-full ${
  isBrowser ? "hover:bg-slate-400" : "border-white border-t-2 pt-4"
}`;
export const MenuItems = () => (
  <>
    <Link to="/" className={linkClassNames}>
      Home
    </Link>
    <Link to="/about" className={linkClassNames}>
      About
    </Link>
    <Link to="/games" className={linkClassNames}>
      Games
    </Link>
  </>
);

export const UserMenuItems = () => (
  <>
    {/*<Link to="/login" href="#" className={linkClassNames}>
      Log In
    </Link>*/}
    <Link
      to="/booking"
      className={`flex items-center justify-center w-full md:w-fit ${
        isMobile && "border-white border-t-2 pt-4"
      }`}
    >
      <button className="flex items-center bg-white text-black h-12 mr-4 my-2 px-4 rounded hover:bg-slate-200 font-bold text-2xl uppercase">
        {/*process.env.NODE_ENV === "development" ? (
          <>Book Now</>
        ) : (
          <>COMING SOON</>
        )*/}
        <>Book Now</>
      </button>
    </Link>
  </>
);

const MainMenu = () => {
  const { showMobileMenu, setShowMobileMenu } = useMainStore((state) => state);

  return showMobileMenu ? (
    <MobileMainMenu />
  ) : (
    <>
      <header className="flex sticky top-0 z-50 w-full h-14">
        <div className="absolute fixed opacity-80 bg-slate-900 h-full w-full" />
        <div className="flex justify-between w-full h-14 uppercase text-white">
          <nav className="z-10 h-full">
            <div className="flex items-center h-full">
              <div
                className={`mr-1 px-4 h-full ${
                  isBrowser && "hover:bg-slate-400"
                }`}
              >
                <Link to="/" className="flex flex-row items-center">
                  <img
                    src={Logo}
                    alt="Opening soon in Cambridge!"
                    className="h-14"
                  />
                  <div>Black Bird VR</div>
                </Link>
              </div>
              {isBrowser && <MenuItems />}
            </div>
          </nav>
          <div
            className="flex items-center z-10"
            onClick={() => {
              if (isMobile) setShowMobileMenu(true);
            }}
          >
            {isBrowser ? (
              <UserMenuItems />
            ) : (
              <IoMenu className="text-4xl mr-6" />
            )}
          </div>
        </div>
      </header>
      <Outlet />
    </>
  );
};

export default MainMenu;
