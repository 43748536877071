import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import TitleBg from "../../images/explode.jpg";
import TitleBottom from "../../images/triangle-top.png";
import GlassBreakEffect from "./GlassBreakEffect";

const TitleCard = () => {
  const paralaxElemBGimage = useRef();
  const [fadeInText, setFadeInText] = useState(false);

  const paralax = () => {
    const distance = window.scrollY;
    paralaxElemBGimage.current.style.transform = `translateY(${
      distance * 0.4
    }px)`;
    /*
    setTimeout(() => {
      document.querySelector("section h3").classList.add("animate-me");
    }, 400);
    */
  };

  useEffect(() => {
    window.addEventListener("scroll", paralax);
    setFadeInText(true);
    return () => {
      window.removeEventListener("scroll", paralax);
    };
  }, []);

  return (
    <>
      <div className="-mt-14 relative overflow-hidden pt-14 px-8 h-[100vw] md:h-[115vh] bg-black flex items-center text-center">
        <div
          ref={paralaxElemBGimage}
          className="absolute top-0 -bottom-10 left-0 -right-10 bg-cover bg-center"
          style={{ backgroundImage: `url(${TitleBg})` }}
        />
        <GlassBreakEffect />
        <div
          className="pointer-events-none absolute z-30 opacity-70 left-0 top-0 right-0 bottom-0 w-full h-full bg-contain bg-no-repeat bg-bottom"
          style={{ backgroundImage: `url(${TitleBottom})` }}
        />
        <div className="absolute top-0 bottom-0 left-0 right-0 opacity-50 bg-black"></div>
        <div
          className={`w-full text-white z-10 transition-opacity ease-in duration-500 ${
            fadeInText ? "opacity-100" : "opacity-0"
          }`}
          style={{ textShadow: "0px 16px 20px black" }}
        >
          <h1 className="md:-mt-24 text-6xl md:text-8xl lg:text-9xl font-face-df">
            Black Bird VR
          </h1>
          <h1 className="flex flex-wrap items-center justify-center mt-4 text-xl md:text-2xl font-bold">
            <Link to="/games/arcade">
              <button className="flex items-center h-12 mx-2 my-2 px-4 rounded hover:bg-blue-700 uppercase border-2 border-blue-500">
                VR ARCADE
              </button>
            </Link>
            <Link to="/games/mobile">
              <button className="flex items-center h-12 mx-2 my-2 px-4 rounded hover:bg-blue-700 uppercase border-2 border-blue-500">
                VR ESCAPE ROOMS
              </button>
            </Link>
          </h1>
          {/*}
          <Link to="/booking">
            <button className="flex items-center bg-black text-white h-12 m-auto my-2 p-10 hover:bg-slate-800 uppercase text-3xl border-2 border-blue-500">
              Book Now
            </button>
          </Link>*/}
          {/* COOKIE NOTICE */}
        </div>
        <div className="absolute top-0 left-1 text-slate-700 text-xs">
          <a href="http://www.freepik.com">
            Designed by pikisuperstar / Freepik
          </a>
        </div>
      </div>
    </>
  );
};

export default TitleCard;
