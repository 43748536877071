import { useEffect, useRef } from "react";
import GroupGoggles from "../../images/group-googles-1000.jpg";
//import Planet from "../../images/planet.jpg";
//import City from "../../images/city.jpg";
//import Characters from "../../images/characters.jpg";
import TitleBottom from "../../images/triangle-top.png";
import { isBrowser } from "react-device-detect";

const FreeRoamContent = () => {
  const paralaxElems = useRef([]);

  const paralax = () => {
    paralaxElems.current.forEach((paralaxElem) => {
      const position = paralaxElem.getBoundingClientRect();
      const distance = position.top;
      paralaxElem.style.transform = `translateY(${distance * 0.1}px)`;
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", paralax);
    return () => {
      window.removeEventListener("scroll", paralax);
    };
  }, []);

  return (
    <div className="relative text-xl bg-slate-700">
      <div
        className="pointer-events-none absolute z-30 opacity-30 left-0 top-0 right-0 bottom-0 w-full h-full bg-contain bg-no-repeat bg-bottom"
        style={{ backgroundImage: `url(${TitleBottom})` }}
      />
      {/* bg image below has the flip-both class*/}
      <div
        className="pointer-events-none absolute flip-both z-30 opacity-50 left-0 top-0 right-0 bottom-0 w-full h-full bg-contain bg-no-repeat bg-bottom"
        style={{ backgroundImage: `url(${TitleBottom})` }}
      />
      {[
        {
          heading: "FREE ROAM VR",
          content: [
            "Step into a world where the boundaries of reality blur and adventure knows no limits.",
            "Welcome to our realm of Free Roam VR Games, where the only thing more immersive than the experience itself is the thrill of exploring it with friends.",
            "Forget confined spaces and stationary controllers. In our Free Roam VR Games, you and your friends are untethered, free to move, interact, and conquer challenges together in a vast digital playground.",
            "Whether you're battling fierce foes, solving mind-bending puzzles, or embarking on epic quests, the possibilities are as boundless as your imagination.",
            "Gone are the days of solitary gaming. With Free Roam VR, you can share the excitement with your friends, strategize in real-time, and forge unforgettable memories as you navigate virtual worlds hand in hand.",
            "So gather your comrades, and prepare to embark on the ultimate adventure! Experience the thrill of Free Roam VR Games today, and let the journey begin.",
          ],
          imageSrc: GroupGoggles,
        },
      ].map((item, i) => (
        <div
          key={i}
          className={`flex md:min-h-[30vh] flex-col ${
            i % 2 === 1 ? "md:flex-row-reverse" : "md:flex-row"
          }`}
        >
          <div className="relative w-full md:w-1/2 p-6 bg-gradient-to-b from-black">
            <h2 className="text-3xl mb-4">{item.heading}</h2>
            {item.content.map((point) => (
              <div className="relative my-3">{point}</div>
            ))}
            <div className="absolute top-0 left-0 bottom-0 right-0 z-30 p-6">
              <h2 className="text-3xl mb-4">{item.heading}</h2>
              {isBrowser &&
                item.content.map((point) => (
                  <div className="relative my-3">{point}</div>
                ))}
            </div>
          </div>
          <div className="relative overflow-hidden w-full md:w-1/2 min-h-[30vh] top-0 left-0 right-0 bottom-0">
            <div
              ref={(elem) => (paralaxElems.current[i] = elem)}
              className="absolute -top-20 left-0 right-0 -bottom-20 bg-cover bg-center"
              style={{ backgroundImage: `url(${item.imageSrc})` }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default FreeRoamContent;
