//import { useEffect } from "react";
//import { Link } from "react-router-dom";
import useUserStore from "../../zustand/userStore";

const Login = () => {
  const userName = useUserStore((state) => state.userName);
  return <>{userName}</>;
};

export default Login;
