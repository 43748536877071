import { lazy, Suspense, useState } from "react";
import { PiCopyrightLight } from "react-icons/pi";
import { IoCallSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import LogoEmboss from "../../images/blackBirdEmbossInv.png";

const ContactMe = lazy(() => import("./ContactMe"));

const TitleHeading = () => {
  const [showingEmail, setShowingEmail] = useState(false);

  let email = showingEmail ? (
    <ContactMe />
  ) : (
    <button onClick={() => setShowingEmail(true)}>
      Click for contact info
    </button>
  );

  return (
    <div className="select-text relative text-lg bg-slate-700 w-full min-h-80">
      <div
        className="absolute opacity-20 right-12 top-12 left-12 bottom-12 lg:left-4 lg:top-4 lg:bottom-4 bg-contain bg-no-repeat bg-center md:bg-right"
        style={{ backgroundImage: `url(${LogoEmboss})` }}
      />
      <div className="relative grid z-10 p-4 pt-8 pl-16 gap-4 lg:grid-cols-3 md:grid-cols-1 w-full">
        <div>
          <div className="text-2xl font-black">COMING SOON</div>
          <div className="text-2xl font-black">TO CAMBRIDGE!</div>
          {/*<div className=" mt-4">Address Street</div>
          <div className="">City, ON P0ST4L</div>*/}
        </div>
        <div>
          {/*
          <div className="text-2xl font-black">HOURS</div>
          <div className="mt-4">Mon - Thur: 3pm - 9:30pm</div>
          <div>Friday: 3pm - 12:00pm</div>
          <div>Saturday: 11am - 12:00pm</div>
          <div>Sunday: 11am - 11:00pm</div>
          */}
        </div>
        <div>
          <div className="text-2xl font-black">CONTACT</div>
          <Suspense fallback={<></>}>
            <div className="mt-4">{email}</div>
          </Suspense>

          {/*}
          <div className="flex flex-row">
            <IoCallSharp className="mt-1 mr-2" />{" "}
            <div className="">Phone: 555-555-5555</div>
          </div>
          */}
        </div>
      </div>
      <div className="flex flex-row mt-16 justify-center h-full text-lg">
        <div className="flex flex-row">
          <PiCopyrightLight className="mt-[5px] mr-2" />
          <div className="mr-8">2024</div>
        </div>
        <Link to="/privacy" className="mr-6">
          Privacy
        </Link>
        <Link to="/terms" className="">
          Terms of Use
        </Link>
      </div>
    </div>
  );
};

export default TitleHeading;
