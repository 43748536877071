import { useEffect } from "react";
import StaticHTML from "./PrivacyHtml";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pt-2 pl-3 bg-white">
      <StaticHTML />
    </div>
  );
};

export default Privacy;
