import { create } from "zustand";
import { v4 as uuidv4 } from "uuid";
import emailjs from "@emailjs/browser";

const url =
  //process.env.NODE_ENV === "production"
  //?
  `${process.env.REACT_APP_PROD_API_URL}/appointments`;
//: `${process.env.REACT_APP_DEV_API_URL}/appointments`;

const useAppointmentStore = create((set, get) => ({
  //appointmentId: null,
  startTimeStringEst: null,
  bookedTimeStringEst: null,
  startTime: "",
  endTime: "",
  userEmail: "",
  userNameFirst: "",
  userNameLast: "",
  numberGuests: 2,
  //confirmationKey: null,
  setAppointmentId: (appointmentId) => set(() => ({ appointmentId })),
  setStartTimeStringEst: (startTimeStringEst) => {
    set(() => ({ startTimeStringEst }));
    if (startTimeStringEst !== null)
      set(() => ({ bookedTimeStringEst: startTimeStringEst }));
  },
  setStartTime: (startTime) => set(() => ({ startTime })),
  setEndTime: (endTime) => set(() => ({ endTime })),
  setUserEmail: (userEmail) => {
    // validate email address
    set(() => ({ userEmail }));
    return true;
    // if not valid return false
  },
  setUserNameFirst: (userNameFirst) => set(() => ({ userNameFirst })),
  setUserNameLast: (userNameLast) => set(() => ({ userNameLast })),
  setNumberGuests: (numberGuests) => set(() => ({ numberGuests })),

  allAppointments: [],
  getAllAppointments: async () => {
    await fetch(url)
      .then((res) => res.json())
      .then((allAppointments) => {
        set({ allAppointments });
        //console.log(allAppointments);
      })
      .catch((err) => console.log(err));
    return get().allAppointments;
  },

  validate: () => {
    return true;
  },

  clearData: () => {
    get().setAppointmentId(null);
    get().setStartTimeStringEst(null); //using bookedTimeStringEst to show in the booking confirmation page
    get().setStartTime("");
    get().setEndTime("");
    get().setUserEmail("");
    get().setUserNameFirst("");
    get().setUserNameLast("");
    get().setNumberGuests(2);
  },

  saveAppointment: async (form) => {
    const body = JSON.stringify({
      startTimeStringEst: get().startTimeStringEst,
      startTime: get().startTime,
      endTime: get().endTime,
      userEmail: get().userEmail,
      userNameFirst: get().userNameFirst,
      userNameLast: get().userNameLast,
      numberGuests: get().numberGuests,
      confirmationKey: uuidv4(),
    });
    //console.log(body);
    await fetch(url, {
      method: "POST", // post to save
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body,
    }).catch((err) => console.log(err));

    emailjs
      .sendForm(
        "service_uucfx1w",
        "template_cent1uh",
        form.current,
        process.env.REACT_APP_EMAIL_CODE
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent!");
        },
        (error) => {
          console.log(error.text);
          console.log("error sending message, try again!");
        }
      );
  },
}));

export default useAppointmentStore;
