import { create } from "zustand";
import VrGroup from "../images/vr-group.jpg";

const useMainStore = create((set) => ({
  showMobileMenu: false,
  setShowMobileMenu: (showMobileMenu) => set(() => ({ showMobileMenu })),

  gameList: [
    { image: VrGroup, label: "ESCAPE ROOM 1" },
    { image: VrGroup, label: "ESCAPE ROOM 2" },
    { image: VrGroup, label: "ESCAPE ROOM 3" },
    { image: VrGroup, label: "ESCAPE ROOM 4" },
    { image: VrGroup, label: "ESCAPE ROOM 5" },
    { image: VrGroup, label: "ESCAPE ROOM 6" },
  ],
}));

export default useMainStore;
