import { useEffect, useRef, useState } from "react";
import g1 from "../../images/glass/104_128.png";
import g2 from "../../images/glass/148_216.png";
import g3 from "../../images/glass/166_256.png";
import g4 from "../../images/glass/188_44.png";
import g5 from "../../images/glass/274_81.png";
import g6 from "../../images/glass/275_184.png";
import g7 from "../../images/glass/280_127.png";
import g8 from "../../images/glass/310_166.png";
import g9 from "../../images/glass/329_81.png";
import g10 from "../../images/glass/365_17.png";
import g11 from "../../images/glass/380_28.png";
import g12 from "../../images/glass/400_206.png";
import g13 from "../../images/glass/416_333.png";
import g14 from "../../images/glass/420_250.png";
import g15 from "../../images/glass/430_315.png";
import g16 from "../../images/glass/444_345.png";
import g17 from "../../images/glass/454_252.png";
import g18 from "../../images/glass/470_18.png";
import g19 from "../../images/glass/544_252.png";
import g20 from "../../images/glass/63_76.png";
import g21 from "../../images/glass/9_174.png";

const GlassBreakEffect = () => {
  const paralaxElemGlassForeground = useRef();
  const paralaxElemGlassBehind = useRef();

  const [fadeInText, setFadeInText] = useState(false);

  const paralax = () => {
    const distance = window.scrollY;
    paralaxElemGlassForeground.current.style.transform = `translateY(${
      distance * 0.75
    }px)`;
    paralaxElemGlassBehind.current.style.transform = `translateY(${
      distance * 0.6
    }px)`;
  };

  useEffect(() => {
    window.addEventListener("scroll", paralax);
    setFadeInText(true);
    return () => {
      window.removeEventListener("scroll", paralax);
    };
  }, []);

  return (
    <>
      <div
        ref={paralaxElemGlassBehind}
        className={`pointer-events-none absolute top-0 -bottom-10 left-0 -right-10 bg-cover bg-center transition-opacity ease-in duration-300 ${
          fadeInText ? "opacity-60" : "opacity-0"
        }`}
      >
        {[
          { src: g1, posX: 104, posY: 128 },
          { src: g5, posX: 274, posY: 81 },
          { src: g6, posX: 275, posY: 184 },
          { src: g9, posX: 329, posY: 81 },
          { src: g10, posX: 365, posY: 17 },
          { src: g11, posX: 380, posY: 28 },
          { src: g12, posX: 400, posY: 206 },
          { src: g13, posX: 416, posY: 333 },
          { src: g14, posX: 420, posY: 250 },
          { src: g15, posX: 430, posY: 315 },
          { src: g18, posX: 470, posY: 18 },
          { src: g20, posX: 63, posY: 76 },
        ].map((img) => (
          <img
            key={`${img.posX}-${img.posY}`}
            src={img.src}
            alt="glass"
            className="absolute scale-[0.2] md:scale-50"
            style={{
              left: fadeInText ? `${(img.posX / 620) * 100}%` : "50%",
              top: fadeInText ? `${(img.posY / 620) * 100}%` : "50%",
              transition: "left 0.15s ease-out, top 0.15s ease-out",
            }}
          />
        ))}
      </div>
      <div
        ref={paralaxElemGlassForeground}
        className={`pointer-events-none absolute z-20 top-0 -bottom-10 left-0 -right-10 bg-cover bg-center transition-opacity ease-in duration-300 ${
          fadeInText ? "opacity-50" : "opacity-0"
        }`}
      >
        {[
          { src: g2, posX: 148, posY: 216 },
          { src: g3, posX: 166, posY: 256 },
          { src: g4, posX: 188, posY: 44 },
          { src: g7, posX: 280, posY: 127 },
          { src: g8, posX: 310, posY: 166 },
          { src: g16, posX: 444, posY: 345 },
          { src: g17, posX: 454, posY: 252 },
          { src: g19, posX: 544, posY: 252 },
          { src: g21, posX: 9, posY: 174 },
        ].map((img) => (
          <img
            key={`${img.posX}-${img.posY}`}
            src={img.src}
            alt="glass"
            className="absolute scale-[0.2] md:scale-75"
            style={{
              left: fadeInText ? `${(img.posX / 620) * 100}%` : "50%",
              top: fadeInText ? `${(img.posY / 620) * 100}%` : "50%",
              transition: "left 0.2s ease-out, top 0.2s ease-out",
            }}
          />
        ))}
      </div>
    </>
  );
};

export default GlassBreakEffect;
