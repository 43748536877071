//import { Link } from "react-router-dom";
import useMainStore from "../../zustand/mainStore";

const GameThumbnails = () => {
  const gameList = useMainStore((state) => state.gameList);
  return (
    <div
      className={`grid content-center m-4 gap-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1`}
    >
      {gameList.map((item) => (
        <div key={item.label} className="card-zoom">
          <div
            className="card-zoom-image  bg-cover bg-center bg-slate-200"
            style={{ backgroundImage: `url(${item.image})` }}
          ></div>
          <h3 className="card-zoom-text">{item.label}</h3>
        </div>
      ))}
    </div>
  );
};

export default GameThumbnails;
