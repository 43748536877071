//import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import VrGroup from "../../images/vr-group.jpg";
//import "../../css/paralax.css";

const TitleHeading = () => {
  return (
    <>
      {/*<div
        class="parallax -mt-14"
        style={{ backgroundImage: `url(${TitleBg})` }}
      ></div>*/}

      <div
        className={`-mt-14 relative ${
          isMobile ? "h-[100vw]" : "h-[calc(100vw*0.444)]"
        } bg-cover bg-center bg-slate-200 flex items-center text-center`}
        style={{ backgroundImage: `url(${VrGroup})` }}
      >
        <div className="absolute top-0 bottom-0 left-0 right-0 opacity-50 bg-black"></div>
        <div
          className="w-full text-white z-10"
          style={{ textShadow: "0px 0px 12px black" }}
        >
          <h1 className="text-4xl font-bold">MOBILE VIRTUAL ESCAPE ROOMS</h1>
          <h1 className="text-2xl font-bold">
            PLAN YOUR ESCAPE TODAY AT YOUR BUSINESS OR HOME
          </h1>
          <Link to="/booking">
            <button className="flex items-center bg-black text-white h-12 m-auto my-2 p-10 hover:bg-slate-800 uppercase text-3xl border-2 border-blue-500">
              {process.env.NODE_ENV === "development" ? (
                <>Book Now</>
              ) : (
                <>COMING SOON</>
              )}
            </button>
          </Link>
          {/* COOKIE NOTICE */}
        </div>
        <div className="absolute bottom-0 left-1 text-slate-500 text-xs">
          <a href="https://www.freepik.com/free-vector/people-vr-glasses-communicate-metaverse-character-floating-futuristic-space-with-neon-light_95177825.htm#query=virtual%20reality%20background&position=48&from_view=search&track=ais&uuid=fdc2763f-daff-45cd-b7f4-9ba345c28fd5">
            Image by redgreystock
          </a>
          on Freepik
        </div>
      </div>
    </>
  );
};

export default TitleHeading;
