//import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useMainStore from "../../zustand/mainStore";
import { IoClose } from "react-icons/io5";
import { MenuItems, UserMenuItems } from "./MainMenu";
import Logo from "../../images/crow-logo-sm.png";

const MobileMainMenu = () => {
  const setShowMobileMenu = useMainStore((state) => state.setShowMobileMenu);

  return (
    <div
      className="w-[100vw] min-h-[100vh] flex flex-col uppercase text-white bg-slate-800"
      onClick={() => {
        if (setShowMobileMenu) setShowMobileMenu(false);
      }}
    >
      <div className="flex justify-between w-full h-14 mb-6">
        <div className="flex flex-row items-center px-4 h-full">
          <Link to="/" className="flex flex-row items-center">
            <img src={Logo} alt="BlackBird Logo" className="h-14" />
            <div>Black Bird VR</div>
          </Link>
        </div>
        <div className="flex items-center">
          <IoClose className="text-4xl mr-6" />
        </div>
      </div>
      <nav className="flex flex-col gap-4 mx-4 text-2xl md:text-lg">
        <MenuItems />
        <UserMenuItems />
      </nav>
    </div>
  );
  // do not use <Outlet /> to avoid showing default index components
};

export default MobileMainMenu;
