import { useEffect, useRef } from "react";
import NeonLandscape from "../../images/neon-landscape.jpg";
import Planet from "../../images/planet.jpg";
import City from "../../images/city.jpg";
import Characters from "../../images/characters.jpg";
import TitleBottom from "../../images/triangle-top.png";
import { isBrowser } from "react-device-detect";

const EscapeRoomContent = () => {
  const paralaxElems = useRef([]);

  const paralax = () => {
    paralaxElems.current.forEach((paralaxElem) => {
      const position = paralaxElem.getBoundingClientRect();
      const distance = position.top;
      paralaxElem.style.transform = `translateY(${distance * 0.1}px)`;
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", paralax);
    return () => {
      window.removeEventListener("scroll", paralax);
    };
  }, []);

  return (
    <div className="relative text-xl bg-slate-700">
      <div
        className="pointer-events-none absolute z-30 opacity-50 left-0 top-0 right-0 bottom-0 w-full h-full bg-contain bg-no-repeat bg-bottom"
        style={{ backgroundImage: `url(${TitleBottom})` }}
      />
      <div
        className="pointer-events-none flip-both absolute z-30 opacity-50 left-0 top-0 right-0 bottom-0 w-full h-full bg-contain bg-no-repeat bg-bottom"
        style={{ backgroundImage: `url(${TitleBottom})` }}
      />
      {[
        {
          heading: "GET READY FOR ADVENTURE",
          content: [
            "Embark on an electrifying adventure into the immersive world of virtual reality with our latest escape room experiences!",
            "Gather your wits and assemble your team, of up to four, for an adrenaline-pumping journey where collaboration is key.",
            "In this heart-pounding virtual realm, you'll find yourself locked in a series of intricately designed rooms, each brimming with cryptic puzzles, hidden clues, and mind-bending challenges.",
          ],
          imageSrc: NeonLandscape,
        },
        {
          heading: "ASSEMBLE YOUR TEAM",
          content: [
            "Assemble your team, each member bringing their unique skills and perspectives to the table.",
            "With communication and teamwork, you'll decipher clues, unlock mysteries, and race against the clock to escape before time runs out.",
            "Traverse through captivating environments that blur the lines between reality and the digital realm, where every twist and turn brings you closer to unraveling the ultimate mystery.",
          ],
          imageSrc: Planet,
        },
        {
          heading: "NEXT LEVEL EXPERIENCE",
          content: [
            "From deciphering ancient codes to navigating treacherous obstacles, each puzzle presents a new test of your collective ingenuity.",
            "With stunning visuals, immersive soundscapes, and intuitive gameplay mechanics, our escape room virtual reality game delivers an unparalleled experience that will leave you breathless and eager for more.",
          ],
          imageSrc: City,
        },
        {
          heading: "PREPARE TO BE CHALLENGED",
          content: [
            "Are you ready to defy the odds, outsmart the challenges, and emerge victorious?",
            "Step into the virtual world and embark on the adventure of a lifetime with our escape room virtual reality games.",
            "Gather your team, brace yourselves, and prepare for the ultimate test of skill, strategy, and teamwork!",
          ],
          imageSrc: Characters,
        },
      ].map((item, i) => (
        <div
          key={i}
          className={`flex md:min-h-[30vh] flex-col ${
            i % 2 === 1 ? "md:flex-row-reverse" : "md:flex-row"
          }`}
        >
          <div className="relative w-full md:w-1/2 p-6 bg-gradient-to-b from-black">
            <h2 className="text-3xl mb-4">{item.heading}</h2>
            {item.content.map((point) => (
              <div className="relative my-3">{point}</div>
            ))}
            <div className="absolute top-0 left-0 bottom-0 right-0 z-30 p-6">
              <h2 className="text-3xl mb-4">{item.heading}</h2>
              {isBrowser &&
                item.content.map((point) => (
                  <div className="relative my-3">{point}</div>
                ))}
            </div>
          </div>
          <div className="relative overflow-hidden w-full md:w-1/2 min-h-[30vh] top-0 left-0 right-0 bottom-0">
            <div
              ref={(elem) => (paralaxElems.current[i] = elem)}
              className="absolute -top-20 left-0 right-0 -bottom-20 bg-cover bg-center"
              style={{ backgroundImage: `url(${item.imageSrc})` }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default EscapeRoomContent;
