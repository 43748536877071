import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import MainMenu from "./components/mainmenu/MainMenu";
//import MobileMainMenu from "./components/mainmenu/MobileMainMenu";
import TitleCard from "./components/home/TitleCard";
import FreeRoamContent from "./components/home/FreeRoamContent";
import EscapeRoomContent from "./components/home/EscapeRoomContent";
import GameThumbnails from "./components/games/GameThumbnails";
//import Carousel from "./components/home/Carousel";
import Games from "./components/games/Games";
import TitleCardArcade from "./components/games/TitleCardArcade";
import TitleCardMobileEscape from "./components/games/TitleCardMobileEscape";
import Privacy from "./components/privacy/Privacy";
import Terms from "./components/terms/Terms";
import Login from "./components/login/Login";
import Booking from "./components/book/Booking";
import BookingComplete from "./components/book/BookingComplete";
import Footer from "./components/footer/Footer";
import "./css/app.css";

const App = () => {
  const pathname = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <>
      <div className="App bg-slate-800 text-white select-none">
        <Routes>
          <Route path="/" element={<MainMenu />}>
            {/* add the property "element={<ElementHere/>}" to <Route/> to add a default element to all pages*/}
            <Route
              index
              element={
                <>
                  <TitleCard />
                  <FreeRoamContent />
                  <GameThumbnails />
                  <EscapeRoomContent />
                  {/*<Carousel />*/}
                  <Footer />
                </>
              }
            />
            <Route
              path="about"
              element={
                <>
                  <EscapeRoomContent />
                  <Footer />
                </>
              }
            />
            <Route
              path="games"
              element={
                <>
                  <Games />
                  <Footer />
                </>
              }
            />
            <Route
              path="games/arcade"
              element={
                <>
                  <TitleCardArcade />
                  <FreeRoamContent />
                  <Footer />
                </>
              }
            />
            <Route
              path="games/mobile"
              element={
                <>
                  <TitleCardMobileEscape />
                  <EscapeRoomContent />
                  <Footer />
                </>
              }
            />
            <Route
              path="privacy"
              element={
                <>
                  <Privacy />
                  <Footer />
                </>
              }
            />
            <Route
              path="login"
              element={
                <>
                  <Login />
                  <Footer />
                </>
              }
            />
            <Route
              path="booking"
              element={
                <>
                  <Booking />
                  <Footer />
                </>
              }
            />
            <Route
              path="booking/complete"
              element={
                <>
                  <BookingComplete />
                  <Footer />
                </>
              }
            />
            <Route
              path="terms"
              element={
                <>
                  <Terms />
                  <Footer />
                </>
              }
            />
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default App;
