//import { useEffect } from "react";
import useAppointmentStore from "../../zustand/appointmentStore";

const BookingComplete = () => {
  const bookedTimeStringEst = useAppointmentStore(
    (state) => state.bookedTimeStringEst
  );

  return (
    <div className="select-text">
      <div className="my-16 text-center text-3xl">Booking complete!</div>
      <div className="my-16 text-center text-3xl">{bookedTimeStringEst}</div>
      <div className="my-16 text-center text-3xl">
        You have been sent a reminder email
      </div>
    </div>
  );
};

export default BookingComplete;
