import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useAppointmentStore from "../../zustand/appointmentStore";
import { IoCaretDown, IoCaretUp } from "react-icons/io5";
import { timeSlotDifference, ScheduleMeeting } from "react-schedule-meeting";

const Booking = () => {
  const useStore = useAppointmentStore();
  const navigate = useNavigate();

  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);

  const weekday = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };
  const [fadeScreenOut, setFadeScreenOut] = useState(false);

  const createDateEST = (date = new Date()) => {
    const d = new Date(date);
    return d;
    /*
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;
    return new Date(utc + 3600000 * -4);
    */
  };

  const appointmentLengthHours = 1;
  const currentDay = new Date(createDateEST());
  const currentHour = currentDay.getHours();
  const nextAvailableHour = currentHour + 2;
  const numDaysFutureAvailable = 30;
  const openTimeSunToThurs = 15;
  const openTimeFriSat = 10;
  const closingTimeSunToThurs = 22;
  const closingTimeFriSat = 23;
  const daysClosed = [weekday.monday, weekday.tuesday];

  const createTimeSlots = (allAppointments) => {
    const createdTimeSlots = [];
    [...Array(numDaysFutureAvailable).keys()].forEach((id) => {
      const incrementingDate = new Date(
        new Date(createDateEST()).setDate(currentDay.getDate() + id)
      );
      // only add day if it's not a day the store is closed
      if (!daysClosed.includes(incrementingDate.getDay())) {
        const timeSlots = {
          id,
          startTime: new Date(
            new Date(
              incrementingDate.setHours(
                // if day is friday or saturday
                [weekday.friday, weekday.saturday].includes(
                  incrementingDate.getDay()
                )
                  ? // if listing tmes for current day,
                    // either start at the nextAvailableHour or usual start time
                    nextAvailableHour > openTimeFriSat && id === 0
                    ? nextAvailableHour
                    : openTimeFriSat
                  : nextAvailableHour > openTimeSunToThurs && id === 0
                  ? nextAvailableHour
                  : openTimeSunToThurs,
                0,
                0,
                0
              )
            )
          ),
          endTime: new Date(
            new Date(
              incrementingDate.setHours(
                [weekday.friday, weekday.saturday].includes(
                  incrementingDate.getDay()
                )
                  ? closingTimeFriSat
                  : closingTimeSunToThurs,
                0,
                0,
                0
              )
            )
          ),
        };
        createdTimeSlots.push(timeSlots);
      }
    });
    // use useStore.allAppointments here to determine unavailableTimeSlots
    let timeSlots = [...createdTimeSlots];
    allAppointments.forEach((appointment) => {
      // can use appointment.isConfirmed to only remove confirmed appointments from list
      const removeAppointment = [
        {
          startTime: new Date(appointment.startTime),
          endTime: new Date(appointment.endTime),
        },
      ];
      timeSlots = [...timeSlotDifference(timeSlots, removeAppointment)];
    });
    //console.log("unavailableTimeSlots", unavailableTimeSlots);
    //console.log("createdTimeSlots", createdTimeSlots);
    setAvailableTimeSlots(timeSlots);
    //console.log("availableTimeSlots", timeSlots);
  };

  useEffect(() => {
    useStore
      .getAllAppointments()
      .then((allAppointments) => createTimeSlots(allAppointments));
  }, []);

  const handleSelectTime = (data) => {
    console.log(data);
    // the start and end date to be saved
    const startTime = data.startTime.toISOString();
    useStore.setStartTime(startTime);
    console.log("setStartTime", startTime);
    // end time = start time + appointmentLengthHours
    const endTime = new Date(
      new Date(startTime).setHours(
        new Date(startTime).getHours() + appointmentLengthHours
      )
    ).toISOString();
    useStore.setEndTime(endTime);
    //console.log("setEndTime", endTime);
    // for displaying date to customer
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      hour12: true,
      //timeZone: "EST",
    };
    const dateString = new Date(startTime).toLocaleDateString(
      undefined,
      options
    );
    useStore.setStartTimeStringEst(dateString);
    console.log("setStartTimeStringEst", dateString);
  };

  const form = useRef();
  const saveAppointment = async () => {
    if (useStore.validate() === true) {
      console.log("saving appointment");
      await useStore.saveAppointment(form);
      console.log("appointment saved");
      useStore.clearData();
      navigate("/booking/complete");
    } else {
      // show errors
    }
  };
  const deleteAppointment = () => {};

  const styles = {
    ".go3046353551": {},
  };

  return (
    <div className="">
      {/*{process.env.NODE_ENV === "development" && (*/}
      <ScheduleMeeting
        borderRadius={10}
        scheduleMeetingStyles={styles}
        primaryColor="#3f5b85"
        eventDurationInMinutes={60}
        availableTimeslots={availableTimeSlots}
        onStartTimeSelect={handleSelectTime}
      />
      {/*})}*/}
      {useStore.startTimeStringEst !== null ? (
        <div className="m-4 md:w-96">{useStore.startTimeStringEst}</div>
      ) : (
        <div className="mt-4 px-4 w-full text-center text-2xl">
          {process.env.NODE_ENV === "development" && (
            <>SELECT A DATE AND TIME</>
          )}
        </div>
      )}
      <div className="mb-24 md:w-96">
        {useStore.startTime !== "" && (
          <form ref={form}>
            <input
              readOnly
              hidden
              name="message"
              value={useStore.startTimeStringEst}
            />
            <div className="flex flex-col gap-4 m-4 align-center">
              First Name
              <input
                name="to_name"
                value={useStore.userNameFirst}
                onChange={(e) => {
                  useStore.setUserNameFirst(e.target.value);
                }}
              />
              Last Name
              <input
                value={useStore.userNameLast}
                onChange={(e) => {
                  useStore.setUserNameLast(e.target.value);
                }}
              />
              Email Address *
              <input
                name="reply_to"
                value={useStore.userEmail}
                onChange={(e) => {
                  useStore.setUserEmail(e.target.value);
                }}
              />
              Number of Guests (2-4) *
              <div className="flex flex-row align-center justify-between w-20 bg-white text-black">
                <div
                  className="flex align-center justify-center h-full w-8"
                  onClick={(e) => {
                    useStore.setNumberGuests(
                      useStore.numberGuests > 2
                        ? useStore.numberGuests - 1
                        : useStore.numberGuests
                    );
                  }}
                >
                  <IoCaretDown className="text-2xl" />
                </div>
                <input
                  className="w-8 text-center pointer-events-none"
                  value={useStore.numberGuests}
                  pattern="[0-9]+"
                  readOnly
                />
                <div
                  className="flex align-center justify-center h-full w-8"
                  onClick={(e) => {
                    useStore.setNumberGuests(
                      useStore.numberGuests < 4
                        ? useStore.numberGuests + 1
                        : useStore.numberGuests
                    );
                  }}
                >
                  <IoCaretUp className="text-2xl" />
                </div>
              </div>
            </div>
          </form>
        )}
        {useStore.userEmail && (
          <div className="flex flex-row gap-4 m-4 align-center justify-center">
            <button
              className="px-6 py-2 rounded bg-black"
              onClick={saveAppointment}
            >
              Book
            </button>
            {/*
        <button
          className="px-6 py-2 rounded bg-black"
          onClick={deleteAppointment}
        >
          Delete
        </button>
        */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Booking;
